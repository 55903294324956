import React from "react"
import { Container, Row, Col, Button } from "reactstrap"

const BackToTop = () => {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }
  return (
    <Container>
      <Row className="px-3">
        <Col md={8}></Col>
        <Col md={4} className="mt-3 mb-4 no-gutters px-0">
          <h3>
            <span className="btt-btn">
              <Button
                className="btn-footer"
                style={{ borderRadius: "0" }}
                onClick={scrollTop}
              >
                Back to Top
              </Button>
            </span>
          </h3>
        </Col>
      </Row>
    </Container>
  )
}

export default BackToTop
